<template  style="background:#f3f3f3;height:100vh">
<div  style="background:#f3f3f3;height:100vh">
<v-container class="login" style="background:#f3f3f3;height:100vh;text-align:CENTER;">
  <img :src="logo" style="width:150px;height:auto;margin:auto;margin-top:3%">
      <v-layout wrap style="background:#f3f3f3;text-align:center">
        
        <v-flex sm12 md4 offset-md4>
          <v-card elevation="4" light tag="section" style="text-align:center;margin-top:6%">
            <v-icon style="font-size: 52px;padding-top:30px;padding-bottom:15px">
              mdi-account-circle-outline
            </v-icon>
            <h4 style="color:rgba(0, 0, 0, 0.65)">Authentification</h4>
            <v-card-text>
              <v-form>
                <input
                  class="form-control"
                  placeholder="Nom d'Utilisateur"
                  type="text"
                  v-model="form.username">
                <input style="margin-top:15px"
                  class="form-control"
                  placeholder="Mot de Passe"
                  type="password"
                  v-model="form.password">
              </v-form>
              
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" v-on:click="submit" :loading="loading"
      :disabled="loading" @click="loader = 'loading'" class='no_margin_for_loader'>
                Valider
              </v-btn>
            </v-card-actions>
            <v-alert type="error" v-if="login_errors" v-on:click="hideAlert">
      {{ error_message }}
    </v-alert>
          </v-card>
        </v-flex>
      </v-layout>
    <v-footer padless style="background:transparent!important">
    <v-col
      class="text-center"
      cols="12"
    >
      {{ new Date().getFullYear() }} — <strong>Kes Lakay</strong>
    </v-col>
  </v-footer>
</v-container>
</div>

</template>

<script>
import { mapActions } from "vuex";
import logo from "../assets/img/logo.png"

export default {
  name: 'LoginView',
  data: () => ({
      items: [
        {
          text: 'Login',
          disabled: false,
          href: '/login',
        }
      ],
      logo: logo,
      form: {
        username: "",
        password: "",
        serial_number: '000-0000-000-0000'
      },
      loader: null,
      loading:false,
      login_errors: false,
      serial_number: '000-0000-000-0000',
      error_message: 'Vérifiez votre nom d\'utilisateur et votre mot de passe puis réessayez.'
    }),
  watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },
    },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      const User = new FormData();
      User.append("username", this.form.username);
      User.append("password", this.form.password);
      User.append("serial_number", this.form.serial_number);
      try {
          await this.LogIn(User);
          this.$router.push("/settings");
          this.login_errors = false
      } catch (error) {
        this.login_errors = true
      }
    },
    hideAlert(){
      this.login_errors = false;
    },
  },
  components: { },
}
</script>

<style type="text/css">
  .success{
    background:#003F74!important;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .flex{
    margin:auto;
  }
  .error--text{
    color:red!important;
  }

  .v-alert{
    margin-top:15px;
  }
  .error {
    background-color: #ff5252 !important;
    border-color: #ff5252 !important;
}
.mdi-alert{
  display:none!important;
}

.v-alert__content{
  text-align:center;
}
.no_margin_for_loader .v-btn__loader div{
margin-top:0px!important;
}
</style>